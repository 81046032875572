<template>
  <div class="city-list_layout">
    <div class="l-search_wrap">
      <div class="input-wrap">
        <input
          type="text"
          class="input"
          placeholder="请输入城市名称搜索"
          v-model="cityName"
          @input.stop="handleInputDebounce"
        />
        <span class="icon-wrap" @click.stop="handleClear" v-if="cityName">
          <i class="icon-delete"></i>
        </span>
      </div>
    </div>
    <div class="l-city_list">
      <van-index-bar :index-list="indexList" :sticky="false">
        <template v-for="(item, index) in cityList">
          <van-index-anchor :index="item.title" :key="item.title" />
          <ul class="list-wrap" :key="`${item.title}_${index}`">
            <li
              class="l-item omit"
              v-for="city in item.items"
              :key="city.name"
              @click.stop="handleCityItem(city.name)"
            >
              {{ city.name }}
            </li>
          </ul>
        </template>
      </van-index-bar>
    </div>
  </div>
</template>

<script>
import cityApi from "@/api/cityApi";
import storage from "@/util/storage.js";
import { debounce, updatePointData } from "@/util/index.js";
import { IndexBar, IndexAnchor } from "vant";

export default {
  name: "cityListIndex",
  components: {
    "van-index-bar": IndexBar,
    "van-index-anchor": IndexAnchor,
  },
  data() {
    this.oldCityList = [];
    this.handleInputDebounce = null;
    return {
      cityName: "",
      cityList: [],
      indexList: [],
    };
  },
  computed: {
    routerQuery() {
      return storage.getStorage("routerQuery", { channelSource: "", cid: "" });
    },
  },
  created() {
    this.getCityPoint();
    this.getCityList();
  },
  mounted() {
    this.handleInputDebounce = debounce(this.handleInput, 500);
  },
  methods: {
    getCityList() {
      cityApi
        .list({ channelId: this.routerQuery.cid })
        .then((res) => {
          if (!res || !Array.isArray(res.data)) res.data = [];
          const list = res.data.sort((a, b) => a.title.localeCompare(b.title));
          this.oldCityList = list;
          this.formatCityList(list);
        })
        .catch((err) => {
          this.$toast(err.errMsg);
        });
    },

    // 点击城市
    handleCityItem(cityName) {
      storage.setStorage({
        key: "cityName",
        data: cityName,
      });

      this.$router.go(-1);
    },

    // 搜索
    handleInput(event) {
      if (!Array.isArray(this.oldCityList) || this.oldCityList.length <= 0)
        return false;
      if (event.target.value) {
        const list = [];
        this.oldCityList.forEach((item) => {
          const items = item.items.filter(
            (item) => item.name.indexOf(event.target.value) !== -1
          );
          if (Array.isArray(items) && items.length > 0) {
            list.push({
              items,
              title: item.title,
            });
          }
        });
        this.formatCityList(list);
      } else {
        this.formatCityList(this.oldCityList);
      }
    },

    handleClear() {
      this.cityName = "";
      this.formatCityList(this.oldCityList);
    },

    formatCityList(list) {
      this.indexList = list.map((item) => item.title);
      this.cityList = Object.freeze(list);
    },

    // 活码长按识别上报
    getCityPoint() {
      this.getPointData({
        configId: "b79c9a60b4c211eb94440242ac120003",
        bizData: JSON.stringify({
          click: "城市页面",
          qrCodeType: window._ICC_LBS_DATA.urlParams.qrCodeType,
          url: window.location.href || "",
          unionId: window._ICC_LBS_DATA.token,
        }),
      });
    },

    // 数据上报
    getPointData(data) {
      const options = Object.assign({}, data, {
        wxCorpId: process.env.VUE_APP_URL_WX_CORPID_MDL, 
        // putChannelCode 来源渠道  qrCodeChannelId 使用渠道
        bizParams: JSON.stringify(this.routerQuery),
      });
      updatePointData(options);
    },
  },
  beforeDestroy() {
    this.handleInputDebounce = null;
  },
};
</script>

<style lang="less" scoped>
@import "./cityList.less";
</style>
